.swiper-slide {
  width: 400px;
  height: 400px;
  transition: transform 0.8s ease-in-out;
}

@media only screen and (max-width: 1024px) {
  .swiper-slide {
    width: 300px;
    height: 300px;
  }
}
