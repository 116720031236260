.icon {
  font-size: 50px;
  /* border-radius: 50%;
  background-color: rgb(0, 255, 0); */
  color: white;
  width: 65px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  max-width: 100vw;
}

.icon:hover {
  transform: scale(1.2);
  transition: 0.3s;
  color: rgb(162, 162, 162);
}

@media screen and (max-width: 500px) {
  .icon {
    width: 50px;
    height: 50px;
    font-size: 30px;
  }
}
